<template>
  <div id="bank-transfer" class="middle-box text-center animated fadeInDown">
    <div>

      <div>
        <img src="@public/img/eurofiscalis/logo.png" alt="Eurofiscalis">
      </div>

      <div v-if="requestLoading" class="'ibox-content p-md sk-loading">

        <div class="sk-spinner sk-spinner-double-bounce">
            <div class="sk-double-bounce1"></div>
            <div class="sk-double-bounce2"></div>
        </div>

      </div>

      <div class="ibox-content m-t" v-if="workerRequest">

        <div class="widget style1 navy-bg">
          <div class="row vertical-align">
            <div class="col-12">
              <i class="fa fa-check fa-2x"></i> 
              <h4 class="font-bold">{{$t('eurofiscalis_workers.bank-transfer.title')}}</h4>
            </div>
          </div>
        </div>

        <div class="form-group text-left" v-html="$t('eurofiscalis_workers.bank-transfer.content',[workerRequest.transaction.amount])"></div>
        <div class="form-group">
          <a href="https://www.eurofiscalis.com/">{{$t('eurofiscalis_workers.bank-transfer.back-to-eurofiscalis')}}</a>
        </div>

      </div>

      <p class="m-t">
          <small>{{ $t("footer.copyright", [year]) }}</small>
      </p>

    </div>
  </div>

</template>

<style scoped>
  .middle-box {
    max-width: 600px;
  }
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { types as eurofiscalisTypes } from '../../../../store/eurofiscalis';
import * as api from '@fwk-client/utils/api';

@Component({
})
export default class BankTransfer extends mixins(GenericPage) {
  
  year = (new Date()).getFullYear();

  workerRequest = null;
  requestLoading = false;

  
  get registerPayment() {
    return this.$store.getters['eurofiscalis/' + eurofiscalisTypes.getters.GET_REGISTER_PAYMENT];
  }

  created() {
    // We get the worker request
    if(this.registerPayment && this.registerPayment.requestId) {
      var options:api.ApiVueOptions =  {
        app: this
      }

      this.requestLoading = true;

      api.getAPI('/api/eurofiscalis/workers/request/'+this.registerPayment.requestId, options).then((response:any) => {
        this.requestLoading = false;
        if(response.request) {  
          this.workerRequest = response.request;
        }
        else {
          this.$router.push('./register').catch(err => {});
        }
      })
    }
    else {
      // we do not have information in the store.
      this.$router.push('./register').catch(err => {});
    }
  }
  
}
</script>